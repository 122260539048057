import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { Box } from '@mui/system';

import { DarkTheme, LightTheme } from './../themes';

interface IThemeContextData {
  themeName: 'light' | 'dark';
  toggleTheme: () => void;
  temaSistema: 'light' | 'dark';
}

const ThemeContext = createContext({} as IThemeContextData);

export const useAppThemeContext = () => {
  return useContext(ThemeContext);
};

interface IAppThemeProviderProps {
  children: React.ReactNode;
}

export const AppThemeProvider: React.FC<IAppThemeProviderProps> = ({ children }) => {
  const [themeName, setThemeName] = useState<'light' | 'dark'>('light');
  const [temaSis, setTemaSis] = useState<'light' | 'dark'>('light');

  useEffect(() => {
    setTemaSis(themeName);
  }, [themeName]);

  const toggleTheme = useCallback(() => {
    setThemeName((oldThemeName) => (oldThemeName === 'light' ? 'dark' : 'light'));
  }, []);

  const theme = useMemo(() => {
    if (themeName === 'light') {
      return LightTheme;
    }

    return DarkTheme;
  }, [themeName]);

  return (
    <ThemeContext.Provider value={{ themeName, toggleTheme, temaSistema: temaSis }}>
      <ThemeProvider theme={theme}>
        <Box width="100vw" height="100vh" bgcolor={theme.palette.background.default}>
          {children}
        </Box>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

// Exporta a função toggleTheme para uso externo, se necessário
export const toggleAppTheme = () => {
  const { toggleTheme } = useAppThemeContext();
  return toggleTheme;
};

// Exporta o valor de temaSis para uso externo, se necessário
export const getTemaSistema = () => {
  const { temaSistema } = useAppThemeContext();
  return temaSistema;
};


