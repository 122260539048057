import { Box, DialogContent, DialogTitle, Grid, LinearProgress, Paper, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import RVencimentos from './RVencimentos';
import RVencimentosdias from './RVencimentosdias';
import { RelatorioEstoqueService } from '../../../shared/services/api/estoquefazenda/Relatorios/RelatoriosService';
import { jwtDecode } from 'jwt-decode';
import { LocalizacaoService } from '../../../shared/services/api/estoquefazenda/localizacao/LocalizacaoService';

interface JwtPayload {
  faz: string;
  loc: string;
}

export const RelatoriosVencimentos: React.FC = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [idLocalizacao, setIdLocalizacao] = useState('');
  const [localizacao, setLocalizacao] = useState('');
  const [dias, setDias] = useState('');

  useEffect(() => {
    const accessToken = localStorage.getItem('APP_ACCESS_TOKEN');
    if (accessToken) {
      const decoded = jwtDecode<JwtPayload>(accessToken);
      setIdLocalizacao(decoded.loc);
      LocalizacaoService.getAllList('', '', decoded.loc)
        .then((result) => {
          if (result instanceof Error) {
            // alert(result.message);
          } else {
            result.data.map(localizacao => ({ label: localizacao.descricao })).forEach(opcao => {
              setLocalizacao(opcao.label);
            });
          }
        });
    }
  }, []);

  const handleVencidos = () => {
    if (idLocalizacao === '') {
      alert('Informe uma Localização');
    } else {
      setIsLoading(true);
      RelatorioEstoqueService
        .Vencimentos(idLocalizacao, 'vencidos', '')
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            RVencimentos(result, localizacao, '');
          }
        });
    }
  };

  const handleVencer = () => {
    if (idLocalizacao === '') {
      alert('Informe uma Localização');
    } else {
      setIsLoading(true);
      RelatorioEstoqueService
        .Vencimentos(idLocalizacao, 'vencer', dias)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            RVencimentos(result, localizacao, dias);
          }
        });
    }
  };

  const handleVencendo = () => {
    if (idLocalizacao === '') {
      alert('Informe uma Localização');
    } else {
      setIsLoading(true);
      RelatorioEstoqueService
        .Vencimentos(idLocalizacao, 'vencendo', '')
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            RVencimentosdias(result, localizacao, '');
          }
        });
    }
  };

  return (
    <Box alignItems="center" width='100%' alignContent='center' height="100%" display="flex" flexDirection="column"  >
      <Box gap={1} padding={1} paddingX={2} display="flex" alignItems="center" alignSelf='center' width='80%' height="20%" margin={1} alignContent='center'></Box>
      <Box width='60%' alignItems="center" component={Paper} height="60%" margin={1}
        alignContent='center'>
        <DialogTitle>Relatório de Vencimentos</DialogTitle>
        <DialogContent dividers>
          {isLoading && (
            <Grid item>
              <LinearProgress variant='indeterminate' />
            </Grid>
          )}
          <Grid container padding={1} item direction="row" gap={2}>
            <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  value={localizacao}
                  label='Localização'
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={7}>
                <Typography
                  component="span"
                  sx={{
                    fontFamily: 'Arial, sans-serif',
                    fontSize: 25,
                    alignContent: 'end'
                  }}
                >
                  Vencidos
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={1}>
                <PictureAsPdfOutlinedIcon sx={{ fontSize: 35 }} color='primary' onClick={() => { handleVencidos(); }} />
              </Grid>
            </Grid>
            <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={10} sm={12} md={5} lg={4} xl={3}>
                <Typography
                  component="span"
                  sx={{
                    fontFamily: 'Arial, sans-serif',
                    fontSize: 25,
                    alignContent: 'end'
                  }}
                >
                  À Vencer em
                </Typography>
              </Grid>
              <Grid item xs={10} sm={10} md={5} lg={6} xl={4}>
                <TextField
                  fullWidth
                  value={dias}
                  onChange={e => setDias(e.target.value)}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={1}>
                <PictureAsPdfOutlinedIcon sx={{ fontSize: 35 }} color='primary' onClick={() => { handleVencer(); }} />
              </Grid>
            </Grid>
            <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={7}>
                <Typography
                  component="span"
                  sx={{
                    fontFamily: 'Arial, sans-serif',
                    fontSize: 25,
                    alignContent: 'end'
                  }}
                >
                  Vencendo
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={1}>
                <PictureAsPdfOutlinedIcon sx={{ fontSize: 35 }} color='primary' onClick={() => { handleVencendo(); }} />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
    </Box>
  );
};