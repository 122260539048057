import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';


import { useDrawerContext } from '../shared/contexts';
import { Alimentacao, Atestado, Bomba, Dashboard, Domingo, HoraExtra, ListagemRDiario, ListagemRMes, Pistola } from '../pages/rh';
import { DetalheDePonto } from '../pages/rh/ponto/ponto';

import { DashboardEstoque, 
  DetalheAbastecimento, 
  DetalheDaOS, 
  DetalheDaSaida, 
  DetalheDaTransferencia, 
  ListagemDeOS, 
  ListagemDeRecebimentos, 
  ListagemDeSaida, 
  ListagemDeTransferencias, 
  RelatoriosLocalizacao,
  RelatoriosVencimentos
} from '../pages/estoque';


export const AppRoutesPrincipal = () => {

  const { setDrawerOptions, menu } = useDrawerContext();

  useEffect(() => {
    setDrawerOptions(menu);
  }, []);


  return (
    <Routes>
      <Route path="/dashboardfazenda" element={<Dashboard />} />
      <Route path="/pontos/detalhe/novo" element={<DetalheDePonto/>}/>
      <Route path="/pontoshoraextra/:id" element={<HoraExtra />} />
      <Route path="/pontosbomba/:id" element={<Bomba />} />
      <Route path="/pontospistola/:id" element={<Pistola />} />
      <Route path="/pontosdomingo/:id" element={<Domingo />} />
      <Route path="/pontosatestado/:id" element={<Atestado />} />
      <Route path="/pontosalimentacao/:id" element={<Alimentacao />} />
      <Route path="/pontosrdiario" element={<ListagemRDiario />} />
      <Route path="/pontosrmes" element={<ListagemRMes />} />

      <Route path="/dashboardestoque" element={<DashboardEstoque/>} />
      <Route path="/abastecimento" element={<DetalheAbastecimento/>} />
      <Route path="/transferenciaestoque" element={<ListagemDeTransferencias/>} />
      <Route path="/transferenciaestoque/detalhe/:id" element={<DetalheDaTransferencia/>} />
      <Route path="/transferenciarecebimento" element={<ListagemDeRecebimentos/>} />
      <Route path="/saidaestoque" element={<ListagemDeSaida/>} />
      <Route path="/saidaestoque/detalhe/:id" element={<DetalheDaSaida/>} />
      <Route path="/restoquelocalizacao" element={<RelatoriosLocalizacao/>}/>
      <Route path="/restoquevencimento" element={<RelatoriosVencimentos/>}/>
      <Route path="/os" element={<ListagemDeOS/>} />
      <Route path="/os/detalhe/:id" element={<DetalheDaOS/>}/>
    </Routes>
  );
};