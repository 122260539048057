/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, useRef, useCallback} from 'react';
import { Avatar, Box, Grid, LinearProgress, Paper, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import {  VForm, useVForm, IVFormErrors, SelectNumber  } from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { Environment } from '../../../shared/environment';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { Funcionariosponto } from '../../../shared/services/api/fazenda/funcionarioponto/funcionarioponto';
import { PontoService } from '../../../shared/services/api/fazenda/pontoservice/pontoservice';

interface IFormData {
  idfun: string;
  presenca: number;

}

const formValidationSchema: yup.ObjectSchema<Omit<IFormData, 'idoperacao' | 'totalgrafificacao'>> = yup.object().shape({
 
  idfun: yup.string().required(),
  presenca: yup.number().required().moreThan(0), 
 
});

export const DetalheDePonto:  React.FC = () => {

 const codigoref = useRef<HTMLInputElement>(null);
 const nomeref = useRef<HTMLInputElement>(null);

  const { id = 'novo' } = useParams<'id'>();
  const [urlimg, setUrlimg] = useState('');
 
  const [nome, setnome] = useState('');
  const [idfunn, setidfun] = useState('');

  const [codigobarra, setcodigobarra] = useState('');
  const { formRef, save } = useVForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Focar no campo de texto quando o componente é montado
    codigoref.current?.focus();
  }, [formRef]);
     
  useEffect(() => {
    if(id === 'novo'){
      formRef.current?.setData({
        idfun: '',
        presenca: 1,
       
      });
      codigoref.current?.focus();
    }else{
      console.log('id diferente de novo');
    }

  }, [id]);

  const novoLancamento = () =>{
    formRef.current?.setData({
      presenca: 1,
    });
    setUrlimg('');
    setnome('');
    setcodigobarra('');

    codigoref.current?.focus();
    
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'F9') {
      // Impedir o comportamento padrão de "Enter" (evitar quebras de linha, etc.)
      event.preventDefault();
  
      // Verificar se formRef.current é não nulo
      if (formRef.current) {
        // Forçar a tipagem do retorno para IFormData
        const formData: IFormData = formRef.current.getData() as IFormData;
  
        // Chamar a função handleSave quando "Enter" for pressionado
        handleSave(formData);
      }
    }
  };

  const handlebusca = useCallback(async (codigobarra: any) => {
    setIsLoading(true);
    if (codigobarra) {
      try {
        const result = await Funcionariosponto.getAll(codigobarra);
        
        setIsLoading(false);
  
        setnome(result.nome);
        setidfun(result.idfun);
        setUrlimg(Environment.URL_IMGFUNCIONARIO + result.urlimg);
  
        // Focar no campo "nome" após um pequeno intervalo
        setTimeout(() => {
          codigoref.current?.focus();
        }, 0);
  
      } catch (error: any) {
        setIsLoading(false);
        alert('Erro no código: ' + error.message);
        novoLancamento()
        setTimeout(() => {
          codigoref.current?.focus();
        }, 0);
      }
    }
  }, [codigobarra]);

  const handleSave = (dados: IFormData) => {
    const dadosAtualizados = { ...dados, idfun: idfunn };
  
    formValidationSchema
      .validate(dadosAtualizados, { abortEarly: false })
  
      .then((result) => {
        setIsLoading(true);
        if (id === 'novo') {
  
          PontoService
            .create(result)
            .then((result) => {
  
              setIsLoading(false);
             
              
              if (result instanceof Error) {
                
                alert('Ponto ja foi lançado!  ' + result.message);

                novoLancamento()

                setTimeout(() => {
                  codigoref.current?.focus();
                }, 0);
  
              } else { 
                 novoLancamento();
                 setTimeout(() => {
                  codigoref.current?.focus();
                }, 0);
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        
        errors.inner.forEach(error => {
          if (!error.path) return;
  
          validationErrors[error.path] = error.message;
        });
  
        formRef.current?.setErrors(validationErrors);
        
      
      })
  };

  return (
    <LayoutBaseDePagina
      titulo={ 'Lançamento de Ponto'}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar={false}
          mostrarBotaoSalvarCarregando={isLoading}
          mostrarBotaoSalvarEFecharCarregando={isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoImpressao={false}
          mostrarBotaoApagarCarregando={isLoading}
          mostrarBotaoImpressaoCarregando={isLoading}
          mostrarBotaoVoltar={false}
          aoClicarEmSalvar={save}
          mostrarBotaoVoltarCarregando={isLoading}
          mostrarBotaoNovoCarregando={isLoading}
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave} onKeyDown={(e) => handleKeyDown(e)} placeholder="Seu Placeholder Aqui">
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={2} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}

            <Grid container item direction="row" spacing={1}>
              <Grid container item direction="row" spacing={2} >
                <Grid item xs={6} sm={6} md={3} lg={2} xl={1.5} >
                  <Box width="100%" marginRight={5} >
                    <Avatar
                      variant='rounded'
                      alt='Remy Sharp'
                      src={urlimg}
                      sx={{ width: '100%', height: 200 }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                  
                    <Grid container item direction="row"  spacing={1} >
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <TextField
                         fullWidth  
                         type='password'
                         value={codigobarra}
                         label='Código'
                         inputRef={codigoref}
                         disabled={isLoading}
                         onChange={e => setcodigobarra(e.target.value)}
                         onKeyDown={(e) => {
                           if (e.key === 'Enter' && codigobarra.trim() !== '') {
                             handlebusca(codigobarra);
                           }
                         }}
                       />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
                        <TextField
                         fullWidth
                         value={nome}
                        
                         label='Nome do colaborador'
                         disabled={true}
                         inputRef={nomeref}
                        />
                      </Grid>
                       <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                         <SelectNumber
                       name='presenca'
                       label='presença'
                       
                       data={[
                        { nameBD: 1, descricao: 'Presença' },
                        { nameBD: 0.5, descricao: 'Meio Período' },
                      
                        ]}
                         disabled={isLoading}
                      />
                      </Grid>
                    </Grid>
                    <Grid container item direction="row" spacing={1} >
                    </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm>

    </LayoutBaseDePagina>
  );
};