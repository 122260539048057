/* eslint-disable @typescript-eslint/no-unused-vars */
import { Api } from '../../axios-config';
import { v4 as uuidv4 } from 'uuid';

export interface IDetalhePonto {
  idfun: string;
  presenca: number;


}


const create = async (dados: IDetalhePonto): Promise<string | Error> => {
  try {
    const guuid = uuidv4();

    const { data } = await Api.post<IDetalhePonto>('/pontoscartao',  {idponto: guuid, ...dados});

    
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};




export const PontoService = {

  create,

 
};