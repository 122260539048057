
export const menurh =  [
  {
    icon: 'dashboardicon',
    path: '/dashboardfazenda',
    label: 'Página Inicial',
  },
  {
    icon: 'badgeicon',
    path: '/pontos/detalhe/novo',
    label: 'Ponto',
  },
  {
    icon: 'scheduleicon',
    path: '/pontoshoraextra/novo',
    label: 'Hora Extra',
  },
  {
    icon: 'agriculture',
    path: '/pontosbomba/novo',
    label: 'Bomba',
  },
  {
    icon: 'editnoteicon',
    path: '/pontospistola/novo',
    label: 'Pistola',
  },
  {
    icon: 'eventicon',
    path: '/pontosdomingo/novo',
    label: 'Domingo',
  },
  {
    icon: 'medicationicon',
    path: '/pontosatestado/novo',
    label: 'Atestado',
  },
  {
    icon: 'restaurantmenuicon',
    path: '/pontosalimentacao/novo',
    label: 'Alimentação',
  },
  {
    icon: 'listicon',
    path: '/pontosrdiario',
    label: 'Listagens',
  },
  
];