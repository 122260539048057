/* eslint-disable @typescript-eslint/no-explicit-any */
import { Environment } from '../../../environment';
import { v4 as uuidv4 } from 'uuid';
import { Apicadastro } from '../axioscadastro';

type NullableDate = Date | null | undefined;

export interface IListagemPatrimonios {
  idpatrimonio: string;
  descricao: string;
  numero: string;
  localizacao: string;
  grupo: string;
}

interface IImage {
  imgPath: string;
  arquivo: File | undefined;
}

export interface IPostPatrimonios {
  descricao: string;
  numero: string;
  idfazenda: string;
  idgrupopatrimonio: string;
  dataaquisicao: Date;
  fabricante: string;
  valoraquisicao: number;
  marca: string;
  modelo: string;
  cor: string;
  ano: number;
  numeroserie: string;
  datagarantia?: NullableDate;
  numeroapolice: string;
  databaixa?: NullableDate;
  motivobaixa: string;
  obs: string;
  seguradora: string;
  status: boolean;
  veiculos: boolean,
  litros: number;
  tipomedicao: string;
}


export interface IDetalhePatrimonios { 
  descricao: string;
  numero: string;
  idfazenda: string;
  idgrupopatrimonio: string;
  dataaquisicao: Date;
  fabricante: string;
  valoraquisicao: number;
  marca: string;
  modelo: string;
  cor: string;
  ano: number;
  numeroserie: string;
  datagarantia?: NullableDate;
  numeroapolice: string;
  databaixa?: NullableDate;
  motivobaixa: string;
  obs: string;
  seguradora: string;
  status: boolean;
  veiculos: boolean,
  litros: number;
  tipomedicao: string;
  fotos: IImage[];
}

type TotalCount = {
    data: IListagemPatrimonios[];
    totalCount: number;
}



interface IListagemPatrimoniosComponente {
  idpatrimonio: string;
  descricao: string;
  numero: string;
  modelo: string; 
  fotos: IImage[];
}

type TotalCountList = {
  data: IListagemPatrimoniosComponente;
  totalCount: number;
}

const getAll = async (page = 1, tipo = '', filter = ''): Promise<TotalCount | Error> => {
  try {
    const urlRelativa = `/patrimonios?page=${page}&limit=${Environment.LIMITE_DE_CADASTRO}&tipo=${tipo}&filter=${filter}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_CADASTRO),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IDetalhePatrimonios | Error> => {
  try {

    const { data } = await Apicadastro.get(`/patrimonios/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
}; 

const create = async (image: File[] | undefined, dados: IPostPatrimonios): Promise<string | Error> => {
  try {

    const formData = new FormData();
    formData.append('idpatrimonio', uuidv4());

    if (image && image.length > 0) {
      image.forEach(file => {
        formData.append('file', file);
      });
    }
       
    Object.keys(dados).forEach((key) => {
      formData.append(key, (dados as any)[key]);
    });

   

    const { data } = await Apicadastro.post<IDetalhePatrimonios>('/patrimonios',  formData);
  
    if (Object.keys(data).length === 0) {
      return 'ok';
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IPostPatrimonios): Promise<void | Error> => {
  try {
    await Apicadastro.put(`/patrimonios/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Apicadastro.delete(`/patrimonios/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

const getAllList = async (filter = '', id = '', filtro = ''): Promise<TotalCountList | Error> => {
  try {

    const urlRelativa: string = id === '' ? `/patrimonioslist?limit=${Environment.LIMITE_DE_LINHAS}&tipo=${filtro}&filter=${filter}` : `/patrimonioslist/${id}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);
    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getAllListNumero = async (filter = ''): Promise<IListagemPatrimoniosComponente | Error> => {
  try {

    const { data } = await Apicadastro.get(`/patrimonionumero?numero=${filter}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};


const updateByIdImg = async (id: string, image: File | undefined): Promise<void | Error> => {
  try {
    
    const formData = new FormData();
     
    formData.append('file', image || ''); 

    await Apicadastro.put(`/patrimoniosupdateimg/${id}`, formData);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteByIdImg = async (id: string, img: string): Promise<void | Error> => {
  try {

    const retira: string = Environment.URL_IMGPATRIMONIO!;
    const imagpath: string = img.substring(retira.length);

    await Apicadastro.delete(`/patrimoniosupdateimg/${id}?img=${imagpath}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const PatrimoniosService = {
  getAll,
  create,
  getById,
  updateById,
  deleteById,
  getAllList,
  updateByIdImg,
  deleteByIdImg,
  getAllListNumero,
};