export const menuestoque =  [
    {
      icon: 'dashboard_customize',
      path: '/dashboardestoque',
      label: 'Página Inicial',
    },
    {
      icon: 'local_gas_station',
      path: '/abastecimento',
      label: 'Abastecimento',
    },
   
    {
      icon: 'move_to_inbox',
      path: '/transferenciarecebimento',
      label: 'Recebimento',
    },
    {
      icon: 'move_to_inbox',
      path: '/transferenciaestoque',
      label: 'Transferência',
    },
    {
      icon: 'move_to_inbox',
      path: '/saidaestoque',
      label: 'Saída',
    },
    {
      icon: 'construction',
      path: '/os',
      label: 'Ordem de Serviço',
    }
  ];

  export const menuestoquerelatorio =  [
    {  
      icon: 'print',
      path: '/restoquelocalizacao',
      label: 'Localização',
    },
    {
      icon: 'print',
      path: '/restoquevencimento',
      label: 'Vencimentos',
    }
  ];