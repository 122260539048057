/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Box, Grid, Icon, IconButton, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { VTextField, VForm, useVForm, VTextFieldData, IVFormErrors, AutoCompleteCultivo, AutoCompletePivotfazenda, AutoCompleteFazenda } from '../../../shared/forms';
import dayjs from 'dayjs';
import { LayoutBaseDePaginaNfe } from '../../../shared/layouts';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { DialogSaida } from './DialogSaida';
import { v4 as uuidv4 } from 'uuid';
import { SaidaService } from '../../../shared/services/api/estoquefazenda/saida/SaidaService';
import { FerramentasDeDetalheNfe } from '../../../shared/components/ferramentas-de-detalhe/FerramentasDeDetalheNfe';
import { jwtDecode } from 'jwt-decode';


interface IFormData {
  idfazenda: string;
  idcultivo?: string | null;
  idpivot?: string | null;
  datalan: Date;
  dataoperacao: Date;
  total: number;
  obs: string;
}

interface IitensSaidaDialog {
  iditens: string;
  idprod: string;
  idlocalizacao: string;
  qtdsaida: number;
  validade?: string | null;
  un: string;
  qtdentrada: number;
  conversor: number;
  preco: number;
  total: number;

  referencia: string;
  qtdestoque: number;
  filtro: string;
  descricao: string;
  localizacao: string;
  reqvencimento: boolean;
}

interface IItensids {
  iditens: string;
}

interface JwtPayload {
  faz: string;
  loc: string;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  idfazenda: yup.string().required(),
  idcultivo: yup.string().nullable().matches(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/, 'Deve ser um UUID válido'),
  idpivot: yup.string().nullable().matches(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/, 'Deve ser um UUID válido'),
  datalan: yup.date().required(),
  dataoperacao: yup.date().required().test('valid-date', 'Selecione uma data válida', function (value) {
    const currentDate = new Date();
    if (!value || value > currentDate || value.getDate() < currentDate.getDate() - 10) return false;
    return true;
  }),
  obs: yup.string().optional().default(''),
  total: yup.number().required(),
});

export const DetalheDaSaida: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();
  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [statuss, setStatuss] = useState(true);
  const [iditens, setIditens] = useState('');
  const [rows, setRows] = useState<IitensSaidaDialog[]>([]);
  const [rowsids, setRowsids] = useState<IItensids[]>([]);
  const [dadositens, setDadositens] = useState<IitensSaidaDialog>();
  const guuid = uuidv4();
  const [idfazenda, setIdfazenda] = useState<string>();
  const [idlocalizacao, setIdlocalizacao] = useState('');

  useEffect(()=>{
    const accessToken = localStorage.getItem('APP_ACCESS_TOKEN');
      if (accessToken) {
        const decoded = jwtDecode<JwtPayload>(accessToken);
        setIdfazenda(decoded.faz);
        setIdlocalizacao(decoded.loc);
      }
  },[id]);

  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      SaidaService.getById(id)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
            navigate('/saidaestoque');
          } else {
            formRef.current?.setData(result);
            setRows(result.itens);

            if (result.datalan) {
              // Converter result.datalan para Day.js object
              const dataLanDayjs = dayjs(result.datalan);

              // Obter a data atual
              const dataAtual = dayjs();

              // Verificar se result.datalan é no máximo 1 dias antes da data atual
              if (dataAtual.diff(dataLanDayjs, 'days') >= 1) {
                setStatuss(false);
              }
            }
          }
        });
    } else {
      formRef.current?.setData({
        idsaida: '',
        idfazenda: '',
        idcultivo: null,
        idpivot: null,
        datalan: new Date(),
        dataoperacao: '',
        obs: '',
      });
      setRows([]);
      setStatuss(true);
      const accessToken = localStorage.getItem('APP_ACCESS_TOKEN');
      if (accessToken) {
        const decoded = jwtDecode<JwtPayload>(accessToken); 
        formRef.current?.setFieldValue('idfazenda', decoded.faz);
      }
      
    }
  }, [id]);

  const [total, setTotal] = useState(0);
  useEffect(() => {
    const totalSum = rows.reduce((accumulator, currentItem) => accumulator + currentItem.total, 0);
    setTotal(totalSum);
  }, [rows]);

  const handleSave = (dados: IFormData) => {
    if (rows.length != 0) {
      dados.total = total;
      const DadosValidados = { ...dados };
      formValidationSchema.
        validate(DadosValidados, { abortEarly: false })
        .then((dadosValidados) => {
          setIsLoading(true);

          if (id === 'novo') {

            SaidaService
              .create(dadosValidados, rows)
              .then((result) => {
                setIsLoading(false);

                if (result instanceof Error) {
                  alert(result.message);
                } else {
                  if (isSaveAndClose()) {
                    navigate('/saidaestoque');
                  } else {
                    navigate(`/saidaestoque/detalhe/${result}`);
                  }
                }
              });

          } else {

            SaidaService
              .updateById(id, dadosValidados, rows, rowsids)
              .then((result) => {
                setIsLoading(false);

                if (result instanceof Error) {
                  alert(result.message);
                } else {
                  if (isSaveAndClose()) {
                    navigate('/saidaestoque');
                  }
                }
              });

          }
        })
        .catch((errors: yup.ValidationError) => {
          const validationErrors: IVFormErrors = {};

          errors.inner.forEach(error => {
            if (!error.path) return;

            validationErrors[error.path] = error.message;
          });

          formRef.current?.setErrors(validationErrors);
        });
    } else {
      alert('A saída deve conter no mínimo um ítem.');
    }

  };

  const handleDeleteEntrada = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      SaidaService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate('/saidaestoque');
          }
        });
    }
  };

  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {

      const meuObjeto = { iditens: id };

      rowsids.push(meuObjeto);

      const indexToDelete = rows.findIndex((item) => item.iditens === id);
      if (indexToDelete !== -1) {
        const updatedList = [...rows];
        updatedList.splice(indexToDelete, 1);
        setRows(updatedList);
      }
    }
  };

  const handleBuscarDados = (id: string) => {
    const foundItem = rows.find((listItem) => listItem.iditens === id);
    if (foundItem) {
      setDadositens(foundItem);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  type RowsType = IitensSaidaDialog[];

  const handleItens = (dados: IitensSaidaDialog) => {
    const index = rows.findIndex((listItem) => listItem.iditens === dados.iditens);

    let updatedList: RowsType;

    if (index !== -1) {
      updatedList = [...rows];
      updatedList[index] = dados;
    } else {
      updatedList = [...rows, dados];
    }
    setRows(updatedList);
  };

  return (
    <LayoutBaseDePaginaNfe
      barraDeFerramentas={
        <FerramentasDeDetalheNfe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando={isLoading}
          mostrarBotaoSalvarEFecharCarregando={isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={false} 
          mostrarBotaoImpressao={false}
          disableBotaoSalvar={statuss ? isLoading : true}
          disableBotaoSalvarEFechar={statuss ? isLoading : true}
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/saidaestoque')}
          mostrarBotaoVoltarCarregando={isLoading}
          aoClicarEmNovo={() => navigate('/saidaestoque/detalhe/novo')}
          mostrarBotaoNovoCarregando={isLoading}
          aoClicarEmApagar={() => handleDeleteEntrada(id)}
        />
      }
    >

      <VForm ref={formRef} onSubmit={handleSave} placeholder=''>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={1} spacing={1}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}

            <Grid container item direction="row" spacing={2}>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={1} >
                  <Grid item xs={6} sm={12} md={6} lg={2} xl={2}>
                    <VTextFieldData
                      fullWidth
                      name='datalan'
                      label='Data do Lançamento'
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={6} sm={12} md={6} lg={2} xl={2}>
                    <VTextFieldData
                      fullWidth
                      name='dataoperacao'
                      label='Data da Saída'
                      disabled={statuss ? isLoading : true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <AutoCompleteFazenda
                      isExternalLoading={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <AutoCompletePivotfazenda
                      isExternalLoading={statuss ? isLoading : true}
                      idfazenda={idfazenda} />
                  </Grid>,
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <AutoCompleteCultivo
                      isExternalLoading={statuss ? isLoading : true} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableContainer component={Paper} variant="outlined" sx={{ width: 'auto', height: 350, maxHeight: 350 }}>
                  <Table size="small">
                    <TableHead sx={{ backgroundColor: '#5E8A71' }}>
                      <TableRow >
                        <TableCell style={{ width: 20, color: '#fff' }} align='right'  >Qtd</TableCell>
                        <TableCell style={{ width: 20, color: '#fff' }} align='left'>UN</TableCell>
                        <TableCell style={{ width: 150, color: '#fff' }} align='left'>Descrição</TableCell>
                        <TableCell style={{ width: 30, color: '#fff' }} align='left'>Local</TableCell>
                        <TableCell style={{ width: 20, color: '#fff' }} align='left'>Referência</TableCell>
                        <TableCell style={{ width: 20, color: '#fff' }} align='center'>Validade</TableCell>
                        <TableCell style={{ width: 2, maxWidth: '1px', maxHeight: '10px', color: '#fff' }} align='center'>
                          <IconButton color='inherit' disabled={statuss ? isLoading : true} aria-label="add to shopping cart" size="large">
                            <AddShoppingCartIcon onClick={() => { setIditens('novo'); setOpen(true); }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map(row => (
                        <TableRow key={row.iditens}
                          hover
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell style={{ width: 20 }} align='right' >{row.qtdsaida.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</TableCell>
                          <TableCell style={{ width: 20 }} align='left'>{row.un}</TableCell>
                          <TableCell style={{ width: 150 }} align='left' >{row.descricao}</TableCell>
                          <TableCell style={{ width: 30 }} align='left'>{row.localizacao}</TableCell>
                          <TableCell style={{ width: 20 }} align='left'>{row.referencia}</TableCell>
                          <TableCell style={{ width: 20 }} align='center'>{row.validade ? dayjs(row.validade).format('DD/MM/YYYY') : ''}</TableCell>
                          <TableCell>
                            <IconButton size="small" onClick={() => { setIditens(row.iditens); handleBuscarDados(row.iditens); setOpen(true); }}>
                              <Icon >edit</Icon>
                            </IconButton>
                            <IconButton disabled={statuss ? isLoading : true} size="small" onClick={() => { handleDelete(row.iditens); }}>
                              <Icon>delete</Icon>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      {isLoading && (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <LinearProgress variant='indeterminate' />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={2} >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <VTextField
                      fullWidth
                      name='obs'
                      label='Observações'
                      multiline
                      rows={4}
                      disabled={statuss ? isLoading : true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <DialogSaida
              id={iditens}
              value={guuid}
              dados={dadositens}
              open={open}
              onDados={handleItens}
              onClose={handleClose}
              status={statuss}
              idlocalizacao={idlocalizacao}
            />
          </Grid>
        </Box>
      </VForm>
    </LayoutBaseDePaginaNfe >
  );
};