/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@mui/material';
import { IVFormErrors, VForm, VTextField, VTextFieldPassword, useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { useAuthContext } from '../../contexts';
import { UsuarioService } from '../../services/api/rh/UsuariosService';

interface IFormData {
  nome: string;
  usuario: string;
  senhas: string;
  novasenha: string;
  confirmarsenha: string;
}

export interface ConfirmationDialogRawProps {
  id: string;
  value: string;
  open: boolean;
  onClose: (value?: string) => void;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  nome: yup.string().required(),
  usuario: yup.string().required(),
  novasenha: yup.string()
    .required('Senha é obrigatória')
    .min(8, 'A senha deve ter pelo menos 8 caracteres')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      'A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial'
    ),
  senhas: yup.string().required(),
  confirmarsenha: yup.string().required().test('confirmarsenha', 'senhas não Confere', function (value, context) {
    const confirmar = context.parent.novasenha || ''; 
    return value === confirmar ;
  }),

});

export const DialogPefil: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {

  const { onClose, value: valueProp, open, ...other } = props;
  const [, setValue] = useState(valueProp);
  const {formRef, save, } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const { logout} = useAuthContext();

  useEffect(() => {
    if(open == true){
      setIsLoading(true);
      UsuarioService.getById(props.id)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            formRef.current?.setData(result);
          }
        });
    }
  }, [open]);

  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        UsuarioService
          .updatesenha(props.id, dadosValidados)
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else {
              logout();
              onClose(valueProp);
            }
          });
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        errors.inner.forEach(error => {
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });
  };

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleCancel = () => {
    onClose();
  };


  return (
    <Dialog
      open={open}
      {...other}
      sx={{ width: '100%'}}
      maxWidth="sm"
    >
      <DialogTitle>Perfil</DialogTitle>
      <DialogContent   sx={{ width: '100%', maxWidth: 300, height: 420, maxHeight: 420}} dividers>
        <VForm ref={formRef}  onSubmit={handleSave} placeholder="">
          <Grid container direction="column">
            <Grid container item direction="row">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={2} > 
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <VTextField
                      fullWidth
                      name='nome'
                      disabled={isLoading}
                      label='Nome'
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <VTextField
                      fullWidth
                      name='usuario'
                      disabled={true}
                      label='usuario'
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <VTextFieldPassword
                      fullWidth
                      name='senhas'
                      disabled={isLoading}
                      label='Senha'
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>         
                    <VTextFieldPassword
                      fullWidth
                      name='novasenha'
                      disabled={isLoading}
                      label='Nova Senha'
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>       
                    <VTextFieldPassword
                      fullWidth
                      name='confirmarsenha'
                      disabled={isLoading}
                      label='Confirmar Senha'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </VForm>
      </DialogContent>
      <DialogActions>
        <Button sx={{ minWidth:'25%'  }} variant='outlined' color='error' onClick={handleCancel}>Cancelar</Button>
        <Button sx={{ minWidth:'25%'  }} variant='outlined' autoFocus onClick={save}>Confirmar</Button>
      </DialogActions>
    </Dialog>
    
  );
}; 