/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Grid, Paper, Skeleton } from '@mui/material';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDeDashboard, LayoutBaseDePagina } from '../../../shared/layouts';
import OutlinedCard from '../../../shared/forms/OutlinedCard';
import GraficoRosca from './GraficoRosca';
import GraficoArea from './GraficoArea';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { DataDashboardService } from '../../../shared/services/api/fazenda/dashboard/DataDashboardService';
import { getTemaSistema } from '../../../shared/contexts';




export const Dashboard = () => {
  const teme = getTemaSistema();
  const [tema, setTema] = useState<'dark' | 'light'>('light');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (teme === 'dark') {
      setTema('dark');
    } else {
      setTema('light');
    }
  }, [teme]); 


  const [Server, setServer] = useState(
    {
      colaboradores: 0, 
      presenca: 0, 
      outros: 0,
      
      presencagrosca: 0, 
      faltagrosca: 0, 
      atestadogrosca: 0,
        
      presencas:[], 
      falta:[],
      atestado:[],
    
      cafe:[], 
      almoco:[],
      janta:[],
        
      horaextra:[], 
      bomba:[],
      pistola:[]
    }
  )

  useEffect(() => {
    setLoading(true);
    DataDashboardService.getByDataDashboard(`${dayjs().format('MMMM')}/${dayjs().year()}`)
        .then((result) => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setServer(result);
            setLoading(false);
          }
        });
  },[])   

  //Ponto Gráfico Rosca
  const seriesData = [Server.presencagrosca ?? 0, Server.faltagrosca ?? 0, Server.atestadogrosca ?? 0];
  const labels = ['Presença', 'Falta', 'Atestado'];
  //Ponto Gráfico Área
  const seriesDataPonto = [
    {
      name: "Presença",
      data: Server.presencas ?? [],
    },
    {
      name: "Falta",
      data: Server.falta ?? [],
    },
    {
      name: "Atestado",
      data: Server.atestado ?? [],
    }
  ];
  const optionsDataPonto = {
    title: {
      text: "Ponto",
    },
    colors:['#00E396', '#FF4560', '#14AEE0'],
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema, 
    },
  };
  //Alimentação Gráfico Área
  const seriesDataAlimentacao = [
    {
      name: "Café",
      data: Server.cafe ?? [],
    },
    {
      name: "Almoço",
      data: Server.almoco ?? [],
    },
    {
      name: "Janta",
      data: Server.janta ?? [],
    },
  ];
  const optionsDataAlimentacao = {
    title: {
      text: "Alimentação",
    },
    colors:['#00E396', '#14AEE0', '#FF4560'],
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    },
  };
  //Extra Gráfico Área
  const seriesDataExtra = [
    {
      name: "Hora Extra",
      data: Server.horaextra ?? [],
    },
    {
      name: "Bomba",
      data: Server.bomba ?? [],
    },
    {
      name: "Pistola",
      data: Server.pistola ?? [],
    },
  ];
  const optionsDataExtra = {
    title: {
      text: "Extra",
    },
    colors:['#00E396', '#14AEE0', '#FF4560'],
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    },
  };

  return (
    <LayoutBaseDeDashboard> 
        <Box display="flex" component={Paper} variant="outlined">
          <Grid container item direction="row">
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <OutlinedCard 
                nome="Colaboradores da Fazenda" 
                valor={Server.colaboradores ?? 0} 
                cor="#95ECD0"
                loading={loading}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <OutlinedCard 
                nome="Presenças do Dia" 
                valor={Server.presenca ?? 0} 
                cor="#82D2ED"
                loading={loading}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <OutlinedCard 
                nome="Outros" 
                valor={Server.outros ?? 0} 
                cor="#DEED82"
                loading={loading}
              />
            </Grid>
          </Grid>
        </Box>
        <Box display="flex" component={Paper} variant="outlined">
          <Grid container item direction="row" >
            <Grid component={Paper} item xs={12} sm={12} md={12} lg={5} xl={3}>
              {loading ? <Skeleton variant="rectangular" height={260}/> : 
                <GraficoRosca
                  series={seriesData}
                  chart={{
                    type: 'donut',
                  }}
                  width={390}
                  labels={labels}
                  themeMode={tema}//"dark" // or "dark" or leave it out for default "dark"
                />
              }
            </Grid>
            <Grid component={Paper} item xs={12} sm={12} md={12} lg={7} xl={9}>
              {loading ? <Skeleton variant="rectangular" height={260}/> : 
                <GraficoArea
                  series={seriesDataPonto} 
                  options={optionsDataPonto}
                />
              }
            </Grid>
            <Grid component={Paper } item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paper >
                {loading ? <Skeleton variant="rectangular" height={260}/> : 
                  <GraficoArea
                    series={seriesDataAlimentacao} 
                    options={optionsDataAlimentacao}
                  />
                }
              </Paper>
            </Grid>
            <Grid component={Paper} item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paper >
                {loading ? <Skeleton variant="rectangular" height={260}/> : 
                  <GraficoArea
                    series={seriesDataExtra} 
                    options={optionsDataExtra}
                  />
                }
              </Paper>
            </Grid>
          </Grid>
        </Box>
    </LayoutBaseDeDashboard>
  );
};